import { isNumeric } from "@_func/regex";
import _ from 'lodash';

/*
** Salvar o briefing
** ================================================== */
export const salvarBriefingsLocal = dados => {
	var briefings = getBriefingsLocal();
	if(briefings) {
		var briefingExistente = _.find(briefings, {id: dados.id});
		if(briefingExistente) {
			var index = _.findIndex(briefings, {id: dados.id});
			briefings[index] = dados;
		} else {
			briefings.push(dados);
		}
	} else {
		briefings = [dados];
	}
	var retorno;
	try {
		localStorage.setItem('@crowdplataforma/briefings', JSON.stringify(briefings));
		retorno = true;
	} catch (error) {
		retorno = false;
	}
	return retorno;
}

/*
** Get briefings
** ================================================== */
export const getBriefingsLocal = () => {
	var dados = localStorage.getItem('@crowdplataforma/briefings');
	var retorno = false;
	if(dados) {
		retorno = JSON.parse(dados);
	}
	return retorno;
}

/*
** Deletar o briefing por id
** ================================================== */
export const deleteBriefingsLocal = id => {
	var briefings = getBriefingsLocal();
	var retorno = false;
	if(briefings) {
		var briefingExistente = _.find(briefings, {id: id});
		if(briefingExistente) {
			briefings = briefings.filter(item => item.id !== id);
			try {
				localStorage.setItem('@crowdplataforma/briefings', JSON.stringify(briefings));
				retorno = true;
			} catch (error) {
				retorno = false;
			}
		}
	}
	return retorno;
}

/*
** Salvar briefing local
** ================================================== */
export const salvarBriefingLocal = (dados) => {
	var retorno = false;
	try {
		localStorage.removeItem('@crowdplataforma/briefing');
		localStorage.setItem('@crowdplataforma/briefing', JSON.stringify(dados));
		retorno = true;
	} catch (error) {
		retorno = false;
	}
	return retorno;
}

/*
** Get briefing atual
** ================================================== */
export const getBriefingLocal = () => {
	var retorno = false;
	try {
		var dados = localStorage.getItem('@crowdplataforma/briefing');
		if(dados) {
			retorno = JSON.parse(dados);
		}
	} catch (error) {
		retorno = false;
	}
	return retorno;
}

/*
** Deletar todos os briefings local
** ================================================== */
export const deleteBriefingLocal = () => {
	var retorno = false;
	try {
		localStorage.removeItem('@crowdplataforma/briefing');
		retorno = true;
	} catch (error) {
		retorno = false;
	}

	return retorno;
}

/*
** Deletar todos os briefings local
** ================================================== */
export const deleteAllBriefingsLocal = () => {
	deleteBriefingLocal();
	var retorno = false;
	try {
		localStorage.removeItem('@crowdplataforma/briefings');
		retorno = true;
	} catch (error) {
		retorno = false;
	}

	return retorno;
}

/*
** Salva oa cesso para evitar novos requests
** ================================================== */
export const salvarNaoExibirNotificacaoTopo = (id) => {
	var sucesso = false;
	try {
		// Salvar permanente
		localStorage.setItem('@crowdplataforma/notificacaotopo', id);
		sucesso = true;
	} catch (error) {
	}
	return sucesso;
};

/*
** Verifica se o acesso está ativo
** ================================================== */
export const isToShowNotificacaoTopo = (id) => {
	var isAtivo = localStorage.getItem('@crowdplataforma/notificacaotopo');
	var retorno = true;

	if(isAtivo && isAtivo !== '' && isAtivo === id) {
		retorno = false;
	}

	return retorno;
};

/*
** Salva ocultar mensagem whatsapp
** ================================================== */
export const salvarNaoExibirMensagemWhatsApp = () => {
	var sucesso = false;
	try {
		localStorage.setItem('@crowdplataforma/msgwhatsapp', (new Date()).getTime());
		sucesso = true;
	} catch (error) {
	}
	return sucesso;
};

/*
** Verifica se o acesso está ativo
** ================================================== */
export const isToHideMsgWhatsApp = () => {
	var isToHide = localStorage.getItem('@crowdplataforma/msgwhatsapp');

	if(isToHide && isToHide !== '') {
		return true;
	}

	return false;
};


/*
** Resetar notificação do topo
** ================================================== */
export const resetExibicaoNotificacaoTopo = () => {
	try {
		localStorage.removeItem('@crowdplataforma/notificacaotopo');
	} catch (error) {
	}
	return true;
};

/*
** Salvar Briefing local
** ================================================== */
export const salvarBriefingSelecionadoLocal = (id) => {
	try {
		localStorage.setItem('@crowdplataforma/briefingdireto', id);
	} catch (error) {
		return false;
	}
	return true;
};

/*
** Remover Briefing local
** ================================================== */
export const removerBriefingSelecionadoLocal = () => {
	try {
		localStorage.removeItem('@crowdplataforma/briefingdireto');
	} catch (error) {
		return false;
	}
	return true;
};

/*
** Verificar Briefing local
** ================================================== */
export const temBriefingSelecionadoLocal = () => {
	var retorno = false;

	try {
		retorno = localStorage.getItem('@crowdplataforma/briefingdireto');
		if(!isNumeric(retorno)) {
			retorno = false;
		}
	} catch (error) {
		return false;
	}

	return retorno;
};
