import React, { useContext, useEffect, useState } from 'react';

// Store
import StoreContext from '@/_/contexts/StoreContext';

// CSS
import c from './LayoutPainel.module.scss';

// Func
import { isHiddenSidebar } from "@_func/cpnt";
import { addClassNivelAcesso } from "@_func/profile";

// Cpnt
import { Sidebar } from "@_cpnt/Sidebar/Sidebar";
import { AvisoTopo } from "@_cpnt/Topo/AvisoTopo/AvisoTopo";
import { Header } from "@_cpnt/Topo/Header/Header";
import { WhatsAppFloatButton } from "@cpnt/WhatsAppFloatButton/WhatsAppFloatButton";
import { isToHideMsgWhatsApp, salvarNaoExibirMensagemWhatsApp } from "@func/local";


export const LayoutPainel = props => {
	const [store, setStore] = useContext(StoreContext);
	const [stateHideMsgWhatsApp, setStateHideMsgWhatsApp] = useState(isToHideMsgWhatsApp());
	const [stateAvisoTopo, setStateAvisoTopo] = useState(false);

	const {
		className = '',
		hideWhatsAppButton,
		...rest
	} = props;

	/*
	** Adiciona a classe no body de acordo com o nível de acesso
	** ================================================== */
	useEffect(() => {
		addClassNivelAcesso(store.usuario.role);
	}, []);

	useEffect(() => {
		addClassNivelAcesso(store.usuario.role);
	}, [store.usuario.role]);

	const buttonWhatsApp = () => {
		if(store.usuario.role === 2 || hideWhatsAppButton) {
			return null;
		}

		return <WhatsAppFloatButton onClickRemove={onClickRemoveX} hideText={stateHideMsgWhatsApp} />
	}

	const onClickRemoveX = (e) => {
		console.log('e:', e)
		e.preventDefault();
		salvarNaoExibirMensagemWhatsApp();
		setStateHideMsgWhatsApp(true);
	};

	const Cpnt = props.component;

	return (
		<div className={`container-wrapper ${className} ${c.container}`}>
			<AvisoTopo {...stateAvisoTopo} setStateAvisoTopo={setStateAvisoTopo} />
			<div className={`container-inner ${c.containerInner}`}>
				<Sidebar {...props} />
				<div className={`container-conteudo ${c['container-conteudo']} ${isHiddenSidebar(store, c['container-conteudo-hide'])}`}>
					<Header />
					<div className={`conteudo ${c['conteudo']}`}>
						<Cpnt {...rest} stateAvisoTopo={stateAvisoTopo} setStateAvisoTopo={setStateAvisoTopo} />
					</div>
				</div>
			</div>
			{buttonWhatsApp()}
		</div>
	);
}
