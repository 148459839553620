import { useRoutes } from 'react-router-dom';

// Layout painel
import { LayoutPainel } from "@_role/Layouts/LayoutPainel/LayoutPainel";

// Itens sidebar
import LayoutBlank from "@_role/Layouts/LayoutBlank/LayoutBlank";

// Screens
import { HomeFreeScreen } from "@_page/Logado/HomeScreen/HomeFreeScreen";
import { sidebarLite } from "@_role/Sidebar/SidebarLite";
import { RedirectTo } from "@cpnt/RedirectTo/RedirectTo";
import CadastrarProcessoSeletivoLite from "@page/Logado/CadastrarProcessoSeletivoLite/CadastrarProcessoSeletivoLite";
import Erro404Logado from "@page/Logado/Erro404Logado/Erro404Logado";
import JobsAdminLite from "@page/Logado/JobsAdminLite/JobsAdminLite";
import NegociarJobs from "@page/Logado/NegociarJobs/NegociarJobs";
import NegociarProcessoSeletivo from "@page/Logado/NegociarProcessoSeletivo/NegociarProcessoSeletivo";
import PerfilAdministrador from "@page/Logado/PerfilAdministrador/PerfilAdministrador";
import ProcessosSeletivosLite from "@page/Logado/ProcessosSeletivosLite/ProcessosSeletivosLite";
import Logout from "@page/SemRestricoes/Logout/Logout";
import { MatchDeProfissionaisScreen } from "@_page/Logado/MatchDeProfissionaisScreen/MatchDeProfissionaisScreen";

export const ROTAS_LITE = [
	{
		path: '/',
		element:
		<LayoutBlank
			title="Página inicial"
			component={RedirectTo}
			redirectTo="/inicio"
		/>,
	},
	{
		path: '/inicio',
		element:
		<LayoutPainel
			sidebar={sidebarLite}
			title="Página inicial"
			component={HomeFreeScreen}
		/>,
	},
	{
		path: '/perfil',
		element:
		<LayoutPainel
			sidebar={sidebarLite}
			title="Perfil"
			component={PerfilAdministrador}
		/>,
	},
	{
		path: '/briefing',
		element:
		<LayoutPainel
			sidebar={sidebarLite}
			title="Propostas de jobs"
			component={ProcessosSeletivosLite}
		/>,
	},
	{
		path: '/briefing/cadastrar',
		element:
		<LayoutPainel
			sidebar={sidebarLite}
			title="Cadastrar proposta de job"
			component={MatchDeProfissionaisScreen}
		/>,
	},
	{
		path: '/briefing/negociacao',
		element:
		<LayoutPainel
			sidebar={sidebarLite}
			title="Negociação"
			component={NegociarProcessoSeletivo}
			className="container-chat"
			hideWhatsAppButton
		/>,
	},
	{
		path: '/contratacoes',
		element:
		<LayoutPainel
			sidebar={sidebarLite}
			title="Contratações"
			component={JobsAdminLite}
		/>,
	},
	{
		path: '/contratacoes/negociacao',
		element:
		<LayoutPainel
			sidebar={sidebarLite}
			title="Negociar contratações"
			component={NegociarJobs}
			className="container-chat"
			hideWhatsAppButton
		/>,
	},
	{
		path: '/logout',
		element:
		<Logout
			title="Fazendo logout, aguarde..."
		/>,
	},
	{
		path: '*',
		element:
		<LayoutPainel
			sidebar={sidebarLite}
			title="Página não encontrada..."
			component={Erro404Logado}
		/>,
	}
];

function ScreensLite() {
	const rotasLight = useRoutes(ROTAS_LITE);
	return rotasLight;
}

export { ScreensLite };
