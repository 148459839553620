import c from './WhatsAppFloatButton.module.scss';

export const WhatsAppFloatButton = ({ onClickRemove, hideText}) => {
	return (
		<a
			href="https://wa.me/5511988141850?text=Oi%2C%20estou%20precisando%20de%20ajuda%20ou%20da%20curadoria%20da%20plataforma,%20voc%C3%AA%20pode%20me%20ajudar%3F"
			target="_blank"
			rel="noreferrer"
			className={c.btn}
		>
			{!hideText ? (
				<span>
					Quer uma força pra usar a plataforma ou uma curadoria personalizada?
					<div className={`x ${c.x}`} onClick={onClickRemove}></div>
				</span>
			) : null}
		</a>
	)
}