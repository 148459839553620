import md5 from 'crypto-js/md5';
import React, { useContext, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { toast as msg } from 'react-toastify';

// Store
import StoreContext from '@/_/contexts/StoreContext';

// CSS
import './Empresas.css';

// API
import { apiCompany } from '@_api/requests/company/company';

// Imagens
import imgIconeEditar from '@svg/regular/pen.svg';
import imgIconePorcentagem from '@svg/regular/percent.svg';
import imgIconeStatus from '@svg/regular/play-pause.svg';

// Funções
import FiltrosWrap from '@cpnt/FiltrosWrap/FiltrosWrap';
import Icone from '@cpnt/Icone/Icone';
import ImgTituloDesc from '@cpnt/ImgTituloDesc/ImgTituloDesc';
import Input2 from '@cpnt/Input2/Input2';
import { PlaceholderRender } from '@cpnt/Placeholder/Placeholder';
import { Select2 } from '@cpnt/Select2/Select2';
import { Select3Lista } from '@cpnt/Select3/Select3';
import Status from '@cpnt/Status/Status';
import Tip from '@cpnt/Tip/Tip';
import TotalPaginacao from '@cpnt/TotalPaginacao/TotalPaginacao';
import PortalEmpresasDetalhes from '@portal/PortaisEmpresas/PortalEmpresasDetalhes/PortalEmpresasDetalhes';
import PortalEmpresasFormCadastro from '@portal/PortaisEmpresas/PortalEmpresasFormCadastro/PortalEmpresasFormCadastro';
import PortalEmpresasFormEdicao from '@portal/PortaisEmpresas/PortalEmpresasFormEdicao/PortalEmpresasFormEdicao';

// Hooks
import useWindowDimension from '@/_/hooks/useWindowDimensions';

// Rotas
import { clearGet, closePortal, getPortalDados, gets, initFuncExec, isShowPortal, setGet, setGetNavigate, showPortal } from '@route/routeUtils';

// Funções
import { dataApiParaBr, dataPorExtenso, statusDefault, tamanhoTela } from '@func/utils';

import { primeiroNome } from "@_func/regex";

// Config
import { Btn } from "@_cpnt/Btn/Btn/Btn";
import { msgs, msgStatus } from "@_func/request";
import apiCompanyIndependent from "@api/companyIndependent/companyIndependent";
import { FILTROS_RESULTADOS, STATUS_DEFAULT, STATUS_FILTRO } from '@config/objetos';
import { BtnLimparFiltros } from '@cpnt/BtnLimparFiltros/BtnLimparFiltros';
import LinhaItem from "@cpnt/LinhaItem/LinhaItem";
import PortalEmpresasFormPlano from "@cpnt/Portais/PortaisEmpresas/PortalEmpresasFormPlano/PortalEmpresasFormPlano";
import { PortalEmpresasLiteFormEdicao } from "@cpnt/Portais/PortaisEmpresas/PortalEmpresasLiteFormEdicao/PortalEmpresasLiteFormEdicao";
import PortalConfirmacao from "@cpnt/Portais/PortaisGerais/PortalConfirmacao/PortalConfirmacao";
import { PortalPerfilUsuario } from "@cpnt/Portais/PortaisGerais/PortalPerfil/PortalPerfilUsuario";
import imgIconeAtivar from '@svg/solid/unlock-keyhole.svg';

const Empresas = props => {

	const [store, setStore] = useContext(StoreContext);
	const location = useLocation();
	const navigate = useNavigate();
	const screen = useWindowDimension();

	/*
	** Funções para executar ao iniciar e sempre que alterar a URL
	** =========================================================== */
	const initFunc = (getsAtualizados = getsDefault) => {
		initFuncExec([
			{
				func: getEmpresas,
				param: getsAtualizados
			},
		], init, location, stateLocation);
	}

	/*
	** @stateLocation
	** Guarda o estado do GET
	**
	** @init
	** Ao iniciar na primeira vez, define como true
	**
	** @getsDefault
	** Tem todos os states iniciais dos campos dos filtros
	**
	** IMPORTATE: não esquecer nem um campo de filtro
	**
	** ================================================== */
	const [init, setInit] = useState(false);
	const [stateLocation, setStateLocation] = useState(false);
	const getsDefault = {
		overlay: [],
		limit: 20,
		page: 1,

		role: 0,

		id_company: store.usuario.id_company,
		independent: 'todos',
		name: '',
		status: 2,
	};
	const [stateGet, setStateGet] = useState(getsDefault);

	/*
	** getsDefault: parametros get iniciais
	** stateLocation: guarda o state atual, no próximo get fica com o valor antigo
	** funcExec: Função a ser executada no início [useEffect] e também ao alterar o GET
	** ================================================== */
	const _setGet = {
		getsDefault: getsDefault,
		setStateGet: setStateGet,
		stateLocation: stateLocation,
		setStateLocation: setStateLocation,
		location: location,
		funcExec: initFunc,
	};

	/*
	** INIT - define os GETS
	** ================================================== */
	useEffect(() => {
		setGet(_setGet);
		if(!init) setInit(true);
	}, []);

	/*
	** LOCATION - define os GETS ao alterar o location
	** ================================================== */
	useEffect(() => {
		if(init) {
			setGet(_setGet);
		}
	}, [location.search]);

	/*
	** States
	** ================================================== */
	const [stateEmpresas, setStateEmpresas] = useState([]);
	const [_stateEmpresas, _setStateEmpresas] = useState([]);
	const [stateEmpresasMetadata, setStateEmpresasMetadata] = useState({});

	/*
	** Loading
	** ================================================== */
	const [loadingGetEmpresas, setLoadingGetEmpresas] = useState(false);
	const [loadingAlterarStatus, setLoadingAlterarStatus] = useState(false);

	/*
	** Detalhes do usuário
	** ================================================== */
	const [showPortalPerfilUsuario, setShowPortalPerfilUsuario] = useState(false);

	/*
	** Ativação de empresa
	** ================================================== */
	const [showPortalAtivarEmpresa, setShowPortalAtivarEmpresa] = useState(false);
	const [stateDadosEmpresaParaAtivar, setStateDadosEmpresaParaAtivar] = useState({});
	const [loadingAtivarEmpresa, setLoadingAtivarEmpresa] = useState(false);

	/*
	** API - Empresas
	** ================================================== */
	const getEmpresas = async (dadosObj = {}) => {
		if(loadingGetEmpresas) {
			return;
		}
		setLoadingGetEmpresas(true);

		var dadosEnviar = clearGet(dadosObj);

		if(dadosEnviar.independent === 'todos') {
			delete dadosEnviar.independent;
		}

		const retGetEmpresas = await apiCompany.all.get(dadosEnviar);

		_setStateEmpresas(msgStatus(retGetEmpresas));

		if(retGetEmpresas.status === 200) {
			setStateEmpresas(retGetEmpresas.data);
			setStateEmpresasMetadata(retGetEmpresas.metadata);
		} else {
			setStateEmpresas([]);
			setStateEmpresasMetadata({});
		}

		setLoadingGetEmpresas(false);
	}

	/*
	** Alterar status da empresa
	** ================================================== */
	const alterarStatusEmpresa = async dadosObj => {
		if(loadingAlterarStatus === dadosObj.id) {
			msg.warning('Por favor, aguarde...');
			return;
		}

		setLoadingAlterarStatus(dadosObj.id);

		const retUpdateCliente = await apiCompany.update.put({
			id: dadosObj.id,
			active: dadosObj.status,
		});

		if(retUpdateCliente.status === 200) {
			msg.success('Status atualizado com sucesso');
			setStateEmpresas([...stateEmpresas.map(item => {
				if(item.id === dadosObj.id) {
					return {
						...item,
						active: dadosObj.status,
					}
				}
				return item;
			})])
		} else {
			msgs([retUpdateCliente]);
		}
		setLoadingAlterarStatus(false);
	}

	/*
	** Aviso de ativar empresa
	** ================================================== */
	const avisoAtivarEmpresa = async dados => {
		setShowPortalAtivarEmpresa(true);
		setStateDadosEmpresaParaAtivar(dados);
	}

	/*
	** API - Ativar empresa
	** ================================================== */
	const ativarEmpresa = async () => {

		if(loadingAtivarEmpresa) {
			msg.warning('Por favor, aguarde...', {autoClose: false});
			return;
		}

		setLoadingAtivarEmpresa(true);

		const retAtivarEmpresa = await apiCompanyIndependent.activate.put({
			id: stateDadosEmpresaParaAtivar.id,
		});

		if(retAtivarEmpresa.status === 200) {
			msg.success('Empresa ativada com sucesso');
			setShowPortalAtivarEmpresa(false);
			getEmpresas();
		} else {
			msgs([retAtivarEmpresa]);
		}

		setLoadingAtivarEmpresa(false);
	}

	/*
	** Nível de acesso por independent
	** ================================================== */
	const nomeNivelAcessoByIndependente = independent => {
		var niveis = {
			0: {
				nome: 'CROWD Plus',
				cor: '#FCEEB5',
				role: 2,
			},
			1: {
				nome: 'Crowd Lite',
				cor: '#E9F6DF',
				role: 9,
			},
		};

		if(niveis[independent]) {
			return niveis[independent];
		}

		return {
			nome: 'Crowd',
			cor: 'var(--color-primary)'
		};
	}

	/*
	** Variaveis de acordo com o tamanho da tela
	** ================================================== */
	var winPosTooltipDireito = tamanhoTela([
		{width: 980, dados: {placement: 'bottom-center'}},
	], {placement: 'right-center'}, screen);

	var winPosTooltipEsquerdo = tamanhoTela([
		{width: 980, dados: {placement: 'bottom-center'}},
	], {placement: 'left-center'}, screen);

	return (
		<>
			<div className="pg-empresas base-full">
				<div className="conteudo-box">
					<div className="titulo-box titulo-box-res">
						<div className="flex flex-column">
							<h3 className="titulo">Empresas</h3>
							<div className="td-desc-alt">Empresas externas com cadastro ativo na plataforma. Os usuários dessas empresas têm acesso Hunter e podem buscar profissionais, publicar briefings e fechar jobs com os profissionais remotos.</div>
						</div>
						<Btn
							_={{
								value: 'Cadastrar empresa',
								onClick: () => {
									showPortal({portal: 'cadastro', stateGet, navigate});
								}
							}}
							size="m"
						/>
					</div>

					<FiltrosWrap>
						<form
							onSubmit={e => e.preventDefault()}
							className="flex flex-wrap wrap-5"
						>
							<div className="input-box flex-1 box-1-980">
								<Input2
									label="Pesquisar empresas"
									_={{
										value: stateGet.name,
										onChange: e => {
											setGetNavigate({
												gets: {name: e.target.value},
												navigate,
												stateGet,
												location,
											});
										}
									}}
								/>
							</div>
							<div className="input-box w-25p box-1-980">
								<Select2
									label="Tipo de cadastro"
									_={{
										value: stateGet.independent,
										onChange: e => {
											setGetNavigate({
												gets: {independent: e ? e.value : null},
												forceCamposVazios: {independent: e ? e.value : null},
												navigate,
												stateGet,
												location,
											});
										},
										options: [
											{
												value: 'todos',
												label: 'Todos',
											},
											{
												value: 1,
												label: 'Crowd Lite',
											},
											{
												value: 0,
												label: 'CROWD Plus',
											},
										],
									}}
									loading={loadingGetEmpresas}
								/>
							</div>
							<div className="input-box w-25p box-1-980">
								<Select2
									label="Status"
									_={{
										value: stateGet.status,
										onChange: e => {
											setGetNavigate({
												gets: {status: e ? e.value : null},
												forceCamposVazios: {status: e ? e.value : null},
												navigate,
												stateGet,
												location,
											});
										},
										options: STATUS_FILTRO,
									}}
									loading={loadingGetEmpresas}
								/>
							</div>
							<div className="input-box w-280 box-1-980 input-box-btnlimpar">
								<Select2
									label="Resultados por página"
									_={{
										value: stateGet.limit,
										onChange: e => {
											setGetNavigate({
												gets: {limit: e.value},
												navigate,
												stateGet,
												location,
											});
										},
										options: FILTROS_RESULTADOS,
									}}
									wrap="flex-1"
								/>
								<BtnLimparFiltros
									_={{
										onClick: e => {
											setGetNavigate({
												gets: {
													name: '',
													status: 2,
													independent: 2,
													limit: 20,
												},
												navigate,
												stateGet,
												location,
											});
										},
									}}
								/>
							</div>
						</form>
					</FiltrosWrap>

					<div id="tabela-container">
						<TotalPaginacao {...{stateGet, stateMetadata: stateEmpresasMetadata, loading: loadingGetEmpresas, getsDefault}} />
						<div className="table-wrap">
							<table className="tabela">
								<thead>
									<tr>
										<th className="col-id">
											<Tip tip="ID">ID único da empresa</Tip>
										</th>
										<th className="">
											<Tip tip="Nome">Nome da empresa</Tip>
										</th>
										<th className="">
											<Tip tip="Responsável">Responsável direto pela empresa</Tip>
										</th>
										<th className="col-telefone">
											<Tip tip="Nível de acesso">Nível de acesso</Tip>
										</th>
										<th className="col-120">
											<Tip tip="Ativação" info>Caso seja um cadastro <span className="color-green">independente</span>, essa coluna exibirá a data de ativação e quem fez a ativação dessa empresa (caso essas informações estejam disponíveis).</Tip>
										</th>
										<th className="col-min-2">
											<Tip
												tip={
													<Icone
														imagem={imgIconePorcentagem}
														cor="#FFF"
														className="margin-auto"
													/>
												}
											>
												Porcentagem da CROWD sobre os jobs dessa empresa
											</Tip>
										</th>
										<th className="col-min-3">
											<Tip
												tip={
													<Icone
														imagem={imgIconeStatus}
														cor="#FFF"
														className="margin-auto"
													/>
												}
											>
												Status da empresa (ativo ou inativo)
											</Tip>
										</th>
										<th className="col-acao">
											<Tip
												tip={
													<Icone
														imagem={imgIconeEditar}
														cor="#FFF"
														className="margin-auto"
													/>
												}
											>
												Editar empresa
											</Tip>
										</th>
									</tr>
								</thead>
								<tbody>
									<PlaceholderRender
										loading={loadingGetEmpresas}
										init={init}
										itens={stateEmpresas}
										request={_stateEmpresas}
										cpnt="PlaceholderEmpresas"
										qtdColunas={8}
									>
										{stateEmpresas.map((val, key) => {

											var role = nomeNivelAcessoByIndependente(val.independent);

											return (
												<tr key={key}>
													<td data-titulo="ID" className="col-id">
														<div className="td-wrap">
															{val.id}
														</div>
													</td>

													<td data-titulo="Empresa" className="col-responsavel">
														<div className="td-wrap">
															<ImgTituloDesc
																wrap="a td-info"
																titulo={val.name}
																desc={val.cnpj}
																classNameTitulo="td-titulo"
																classNameDesc="td-desc"
																responsivo={true}
																onClick={() => showPortal({portal: 'detalhes', dados: val.id, stateGet, navigate})}
															/>
														</div>
													</td>

													<td data-titulo="Responsável">
														<div className="td-wrap">
															<ImgTituloDesc
																wrap="td-info"
																titulo={val.name_responsible || '(nome não informado)'}
																desc={val.email_responsible || 'e-mail não informado'}
																img={`https://www.gravatar.com/avatar/${md5(val.email_responsible)}?d=blank&s=60`}
																imgPreview={`https://www.gravatar.com/avatar/${md5(val.email_responsible)}?d=blank&s=200`}
																placeholder='userpicture'
																classNameTitulo="td-titulo"
																classNameDesc="td-desc text-one-line"
																classNameImgBox="foto-wrap"
																responsivo={true}
																_tipDefaults={winPosTooltipDireito}
															/>
														</div>
													</td>

													<td data-titulo="Nível de acesso" style={{backgroundColor: role.cor}}>
														<div className={`td-wrap align-center`}>
															<div>{role.nome}</div>
															<div className="a" onClick={() => showPortal({portal: 'plano', dados: val.id, stateGet, navigate})}>[alterar]</div>
														</div>
													</td>

													<td data-titulo="Ativação" className="align-center">
														<div className="td-wrap">
															{val.activate_at ? (
																<Tip
																	tip={
																		<div className="align-center">
																			<div>{dataApiParaBr(val.activate_at)}</div>
																			<div>{primeiroNome(val.activate_by_user_name)}</div>
																		</div>
																	}
																	closeOnLeave={false}
																	_defaults={winPosTooltipEsquerdo}
																>
																	<LinhaItem
																		titulo="Ativado em"
																		desc={dataPorExtenso(val.activate_at)}
																		alt
																	/>
																	<LinhaItem
																		titulo="Ativado por"
																		desc={val.activate_by_user_name}
																		alt
																	/>
																	<div className="margin-t-10"><span className="link" onClick={() => {
																		setShowPortalPerfilUsuario(val.activate_by_user);
																	}}>Ver detalhes do usuário</span></div>
																</Tip>
															) : (
																val.independent === 1 ? (
																	<div>
																		<Tip
																			tip={
																				<div className="a" onClick={() => avisoAtivarEmpresa(val)}>
																					<Icone
																						imagem={imgIconeAtivar}
																						cor="#eab20e"
																						className="margin-auto cursor-pointer"
																					/>
																					<div className="td-desc link">Ativar</div>
																				</div>
																			}
																			info
																		>
																			Ativa o acesso dessa empresa e notifica os responsáveis e administradores.
																		</Tip>
																	</div>
																) : (
																	<div>-</div>
																)
															)}
														</div>
													</td>

													<td data-titulo="Porcentagem" className="align-center">
														<div className="td-wrap">
															{val.percentage}%
														</div>
													</td>

													<td data-titulo="Status" className="relative">
														<div className="td-wrap scroll-visible align-center">
															<Tip
																tip={
																	<div className="a">
																		<Status
																			title="Alterar status"
																			imagem={imgIconeEditar}
																			cor="#555"
																			className="margin-auto"
																			status={val.active === 1 ? 1 : 2}
																		/>
																		<div className="td-desc-alt">{statusDefault(val.active)}</div>
																	</div>
																}
																click={true}
																show={loadingAlterarStatus === val.id ? true : false}
																_defaults={winPosTooltipEsquerdo}
															>
																<Select3Lista
																	titulo={<h3 className="margin-0">Status do projeto</h3>}
																	options={STATUS_DEFAULT}
																	optionsType="radio"
																	optionsChecked={{
																		value: val.active,
																	}}
																	onClick={e => {
																		alterarStatusEmpresa({
																			id: val.id,
																			status: e.value,
																			nomeEmpresa: e.label,
																		})
																	}}
																	loading={loadingAlterarStatus === val.id ? true : false}
																	labelClass="flex-1"
																	posicao=""
																	classNameDefault=""
																/>
															</Tip>
														</div>
													</td>

													<td data-titulo="Ação" className="align-center">
														<div className="td-wrap">
															<Icone
																title="Editar cliente"
																imagem={imgIconeEditar}
																cor="#555"
																className={`margin-auto cursor-pointer`}
																onClick={() => showPortal({portal: val.independent === 1 ? 'edicao-lite' : 'edicao', dados: val.id, stateGet, navigate})}
															/>
														</div>
													</td>

												</tr>
											)
										})}
									</PlaceholderRender>
								</tbody>
							</table>
						</div>
						<TotalPaginacao {...{stateGet, stateMetadata: stateEmpresasMetadata, loading: loadingGetEmpresas, getsDefault}} />
					</div>
				</div>
			</div>

			<PortalPerfilUsuario
				id={showPortalPerfilUsuario}
				show={showPortalPerfilUsuario ? true : false}
				onClose={e => {
					setShowPortalPerfilUsuario(false);
				}}
			/>

			<PortalEmpresasFormCadastro
				show={isShowPortal({portal: 'cadastro', stateGet})}
				onClose={() => closePortal({portal: 'cadastro', stateGet, navigate})}
				onSuccess={() => {
					getEmpresas(gets());
					closePortal({portal: 'cadastro', stateGet, navigate});
				}}
				closeOnEsc={false}
			/>

			<PortalEmpresasFormEdicao
				show={isShowPortal({portal: 'edicao', stateGet})}
				id={getPortalDados({portal: 'edicao', stateGet})}
				onClose={() => closePortal({portal: 'edicao', stateGet, navigate})}
				onSuccess={() => {
					getEmpresas(gets());
					closePortal({portal: 'edicao', stateGet, navigate});
				}}
				closeOnEsc={false}
			/>

			<PortalEmpresasFormPlano
				show={isShowPortal({portal: 'plano', stateGet})}
				id={getPortalDados({portal: 'plano', stateGet})}
				onClose={() => closePortal({portal: 'plano', stateGet, navigate})}
				onSuccess={() => {
					getEmpresas(gets());
					closePortal({portal: 'plano', stateGet, navigate});
				}}
				closeOnEsc={false}
			/>

			<PortalEmpresasLiteFormEdicao
				show={isShowPortal({portal: 'edicao-lite', stateGet})}
				id={getPortalDados({portal: 'edicao-lite', stateGet})}
				onClose={() => closePortal({portal: 'edicao-lite', stateGet, navigate})}
				onSuccess={() => {
					getEmpresas(gets());
					closePortal({portal: 'edicao-lite', stateGet, navigate});
				}}
				closeOnEsc={false}
			/>

			<PortalEmpresasDetalhes
				show={isShowPortal({portal: 'detalhes', stateGet})}
				id={getPortalDados({portal: 'detalhes', stateGet})}
				onClose={() => closePortal({portal: 'detalhes', stateGet, navigate})}
				onSuccess={() => closePortal({portal: 'detalhes', stateGet, navigate})}
				closeOnEsc={false}
			/>

			<PortalConfirmacao
				show={showPortalAtivarEmpresa}
				titulo="Deseja ativar o acesso dessa empresa?"
				portalClassName="w-800"
				classNameBtnConfirmar="btn btn-green"
				btnConfirmarTexto="Ativar acesso"
				desc={
					<>
						<div>Ao ativar o acesso, essa empresa poderá visualizar todas as funcionalidades disponíveis na plataforma.</div>
						<div className="margin-t-10">O responsável por essa empresa e os administradores da plataforma CROWD também serão notificados.</div>
						<h3 className="">Dados da empresa</h3>
						<LinhaItem
							titulo="Nome"
							desc={stateDadosEmpresaParaAtivar.name}
							alt
						/>
						<LinhaItem
							titulo="CNPJ"
							desc={stateDadosEmpresaParaAtivar.cnpj}
							alt
						/>
						<LinhaItem
							titulo="Nome do responsável"
							desc={stateDadosEmpresaParaAtivar.name_responsible}
							alt
						/>
						<LinhaItem
							titulo="E-mail do responsável"
							desc={stateDadosEmpresaParaAtivar.email_responsible}
							alt
						/>
						<LinhaItem
							titulo="ID de cadastro"
							desc={stateDadosEmpresaParaAtivar.id}
							alt
						/>
					</>
				}
				onClose={() => {
					setShowPortalAtivarEmpresa(false);
					setStateDadosEmpresaParaAtivar({});
				}}
				onClickCancelar={() => {
					setShowPortalAtivarEmpresa(false);
				}}
				onClickConfirmar={ativarEmpresa}
				loadingConfirmar={loadingAtivarEmpresa}
			/>

		</>
	);
}

export default Empresas;
